<template>
  <ValidationObserver ref="observer" rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      @submit="handleSubmit(onSubmit)"
      @close="isOpen = false, $emit('close')"
      submit="Submit"
      :loading="isLoading"
      button-class="bg-dynamicBackBtn text-white"
      width="520px"
    >
      <template v-slot:title>
        <div class="flex justify-start items-center">
          <BackButton @onClick="isOpen = false, $emit('close')" variant="secondary" />
          <span class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
            Promote Employee
          </span>
        </div>
      </template>
      <template>
        <div class="border-b border-dashed border-romanSilver mb-5">
          <div class="flex justify-between items-start w-full gap-2">
            <div class="w-4/12 h-60 rounded-md text-blueCrayola font-normal">
                <img class="h-56 rounded-md" v-if="employeeInfo.photo" :src="employeeInfo.photo" />
                <div v-else class="h-56 border rounded-md flex justify-center items-center">
                <span style="font-size:60px">
                  {{ $getInitials(`${employeeInfo.fname} ${employeeInfo.lname}`) }}
                </span>
                </div>
              </div>
            <div class="w-8/12 flex flex-col justify-start items-start gap-2">
              <h4 class="font-bold text-lg leading-5 text-darkPurple">
                {{ employeeInfo.fname }} {{ employeeInfo.lname }}
              </h4>
              <div class="flex flex-col justify-start items-center w-full gap-2">
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Function:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    {{ employeeInfo.function }}
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Designation:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    {{ employeeInfo.designation }}
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Job Level:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    <span v-if="employeeInfo.level">
                      {{ employeeInfo.level.name }}
                    </span>
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Office Location:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    {{ employeeInfo.location }}
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Manager:
                  </p>
                  <template v-if="(employeeInfo.lineManager)">
                    <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                      {{ employeeInfo.lineManager.fname }} {{ employeeInfo.lineManager.lname }}
                    </p>
                  </template>
                  <p v-else>-</p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Length of Service:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    <span v-if="employeeInfo.yearsOfService">
                      {{ employeeInfo.yearsOfService }}
                    </span>
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Last Performance Score:
                  </p>
                  <template v-if="employeeInfo.performanceScore">
                    <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                      {{ employeeInfo.performanceScore }}
                      <span class="text-red-400" v-if="employeeInfo.performanceScore <= 2">(Poor)</span>
                      <span class="text-yellow-400" v-if="(employeeInfo.performanceScore > 2 && employeeInfo.performanceScore < 4)">
                        (Average)
                      </span>
                      <span class="text-yellow-400" v-if="(employeeInfo.performanceScore > 4)">(Excellent)</span>
                    </p>
                  </template>
                  <p v-else>-</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template>
        <div class="flex flex-col justify-start items-start gap-5 my-5">
          <div class="flex justify-between items-center w-full">
            <p class="font-semibold text-sm leading-5 text-romanSilver">
              Salary Range:
            </p>
            <p class="font-semibold text-sm leading-5 text-jet">
              <span v-if="employeeInfo.paygrade">
                {{ convertToCurrency(employeeInfo.paygrade.rangeMinimum) }}
                -
                {{ convertToCurrency(employeeInfo.paygrade.rangeMaximum) }}
              </span>
              <span v-else> - </span>
            </p>
          </div>
          <div class="flex justify-between items-center w-full">
            <p class="font-semibold text-sm leading-5 text-romanSilver">
              Current Annual Gross
            </p>
            <p class="font-semibold text-sm leading-5 text-jet">
              <span v-if="employeeInfo.employee.grossPay">
                {{ convertToCurrency(employeeInfo.employee.grossPay) }}
              </span>
              <span v-else> - </span>
            </p>
          </div>
        </div>
      </template>
      <template>
        <div class="container flex flex-col justify-start items-start gap-5">
          <div class="container flex flex-col justify-start items-start">
            <div class="flex justify-between gap-0">
              <p class="font-normal text-sm leading-5 text-jet">
                New Designation
              </p>
              <Icon v-tooltip.top="`Designation selected must have the required level`"
                  class-name="text-jet h-3 w-1 mt-1 flex cursor-pointer" icon-name="tooltip" size="xsm"
              />
            </div>
            <Select
              placeholder="Select"
              class="container"
              :rules="['required']"
              :options="designationOptions"
              v-model="payload.newDesignationId"
              @input="onChangeDesignation"
            />
          </div>
          <div class="container flex flex-col justify-start items-start">
            <p class="font-normal text-sm leading-5 text-jet">
              Job Level
            </p>
            <Select
              placeholder="Select"
              class="container"
              :rules="['required']"
              :options="levelOptions"
              v-model="payload.newLevelId"
              @input="getPaygradeByOrgParameters"
            />
          </div>
          <div class="container flex flex-col justify-start items-start">
            <p class="font-normal text-sm leading-5 text-jet">
              Office Location
            </p>
            <Select
              placeholder="Select"
              class="container"
              :rules="['required']"
              :options="locationOptions"
              v-model="payload.newOfficeId"
              @input="getPaygradeByOrgParameters"
            />
          </div>
          <div class="container flex flex-col justify-start items-start gap-2">
            <p class="font-normal text-sm leading-5 text-jet">
              Pay Band/Range
            </p>
            <div class="container flex justify-between items-center gap-5">
              <span class="w-1/2 payInfo payInfo_fakeInput text-center">
                {{ convertToCurrency(payGrade.rangeMinimum) }}
              </span>
              <p class="font-normal text-sm leading-5 text-jet">to</p>
              <span class="w-1/2 payInfo payInfo_fakeInput text-center">
                {{ convertToCurrency(payGrade.rangeMaximum) }}
              </span>
            </div>
          </div>
          <div class="container flex flex-col justify-start items-start">
            <template v-if="checkAdjustment">
              <div class="w-full px-10 py-2 flex self-center mb-2 rounded-md bg-red-100 border-desire border">
                <Icon icon-name="icon-info" size="xs" class="text-desire" />
                <span class="text-desire w-full rounded-md text-center text-sm">
                  Annual Salary is outside employee paygrade.
                </span>
              </div>
            </template>
            <TextInput
              type="number"
              placeholder="Enter"
              class="container"
              :rules="['required']"
              label=" New Gross Annual Salary"
              v-model.number="payload.newAnnualSalary"
            />
          </div>
          <div class="w-full">
            <validation-provider class="w-full" name="New Manager" :rules="{required: true}" v-slot="{ errors }">
              <label class="date-label">Reporting To</label>
              <v-select
                :clearable="false"
                label="New Manager"
                class="style-select"
                :reduce="option => option.userId"
                v-model="payload.newManagerId"
                @search="onSearchEmployees($event, 100)"
                :options="employeesOptions"
                :filterable="false"
              >
                <template #option="{fname, lname, orgFunction, userDesignation, photo }">
                  <div class="flex flex-row justify-start gap-2">
                    <template>
                      <img v-if="photo" class="w-8 h-8 rounded-md" :src="photo" alt="profile pic" />
                      <div v-else class="w-8 h-8 px-2 py-2 rounded-md border flex justify-center items-center">
                        <span class="text-blueCrayola text-xs font-semibold text-center">
                          {{ $getInitials(`${fname} ${lname}`) }}
                        </span>
                      </div>
                    </template>
                    <div class="hover:text-white flex flex-col justify-start">
                      <p class="font-bold text-sm cursor-pointer">
                        {{ fname }} {{ lname }}
                      </p>
                      <div class="flex flex-row justify-start items-center gap-2" style="font-size:10px">
                        <span class="uppercase">{{ orgFunction ? orgFunction.name : '' }}</span>
                        <span class="capitalize">{{ userDesignation ? `${userDesignation.name}` : '' }}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <template #selected-option="{fname, lname}">
                  <div>
                    <p class="">{{ fname }} {{ lname }}</p>
                  </div>
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <Icon style="margin-right:14px" icon-name="caret-down" size="xsm" />
                  </span>
                </template>
                <template #list-footer>
                  <li class="text-center text-xs">
                    Type name of employee to search more
                  </li>
                </template>
              </v-select>
              <small class="text-desire">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </div>
      </template>
      <div class="flex flex-col justify-start items-start gap-5 mt-5">
        <template>
          <div class="w-8/12">
            <span class="font-semibold text-base leading-5 text-darkPurple">
              Effective
            </span>
            <RadioButton
              v-model="isEffectiveDate"
              space-between="mr-2 ml-2 text-jet text-base font-normal leading-5"
              :options="[
                { name: 'Immediately', value: 'immediately' },
                { name: 'Set Date', value: 'set_date'},
              ]"
            />
          </div>
          <div v-if="isEffectiveDate === 'set_date'" class="w-full">
            <div class="w-full flex flex-col justify-start gap-2">
              <p class="text-jet text-sm mr-5">Start Date</p>
              <validation-provider class="w-full" name="Effective date" :rules="{required: true}" v-slot="{ errors }">
                <div :class="errors[0] ? 'border border-desire rounded-md' : 'date-flex'">
                  <datepicker
                    placeholder="--Start Date--"
                    input-class="date-input"
                    style="width:100%;"
                    :rules="['required']"
                    v-model="payload.effectiveDate"
                  />
                </div>
                <small class="text-desire">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
        </template>
        <template>
          <validation-provider class="w-full" name="Justification" :rules="{required: true}" v-slot="{ errors }">
            <div class="flex flex-col justify-start items-start w-full gap-1">
              <span class="font-normal text-sm text-darkPurple">Justification</span>
              <div class="w-full flex flex-col justify-start gap-2">
                <Textarea
                  class="w-full"
                  label="Justification"
                  placeholder="Text Area"
                  :height="144" :maxlength="3"
                  v-model="payload.justification"
                />
                <div class="w-full flex flex-row justify-between text-sm">
                  <p v-if="payload.justification.length > 300" class="text-flame w-full whitespace-nowrap">
                    * Justification should not exceed max count of 300
                  </p>
                  <p class="font-normal text-xs leading-5 text-jet flex justify-end w-full">
                    {{ payload.justification.length }}/300
                  </p>
                </div>
              </div>
            </div>
            <span class="text-flame">{{ errors[0] }}</span>
          </validation-provider>
        </template>
        <template>
          <div class="flex flex-col justify-start items-start w-full gap-2 -mt-2">
              <span class="text-base font-semibold leading-5 text-darkPurple">
                Attach Letter
              </span>
              <div class="w-full flex items-center justify-center border border-dashed p-3 border-romanSilver rounded-md">
              <div @click="isOpenAttachment = !isOpenAttachment"
                class="bg-ghostWhite p-5 rounded-md shadow cursor-pointer">
                <Icon @click="isOpenAttachment = !isOpenAttachment" icon-name="icon-plus-grey" size="s" />
              </div>
            </div>
          </div>
        </template>
        <template v-if="isOpenAttachment">
        <div class="overlayer flex flex-wrap justify-center items-center">
          <Card class="bg-white relative p-4 flex flex-col justify-end items-end gap-2">
            <div @click="isOpenAttachment = !isOpenAttachment">
              <Icon
                icon-name="close"
                size="xs"
                class="text-darkPurple cursor-pointer"
              />
            </div>
            <file-upload />
          </Card>
        </div>
      </template>
      </div>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
  import * as _ from 'lodash';
  import { format } from "date-fns";
  import VSelect from "vue-select"
  import "vue-select/dist/vue-select.css"
  import Datepicker from "vuejs-datepicker";
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import FileUpload from '@scelloo/cloudenly-ui/src/components/file-upload';
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
  import TextInput from "@scelloo/cloudenly-ui/src/components/text";
  import Card from "@scelloo/cloudenly-ui/src/components/card";
  import Textarea from "@scelloo/cloudenly-ui/src/components/text-area";
  import Select from "@scelloo/cloudenly-ui/src/components/select";
  import RadioButton from "@scelloo/cloudenly-ui/src/components/radio";
  import RightSideBar from "@/components/RightSideBar";
  import Icon from "@/components/Icon";

  export default {
    name: 'CompensationPromoteEmployee',
    components: {
      ValidationObserver,
      ValidationProvider,
      VSelect,
      FileUpload,
      Datepicker,
      BackButton,
      RadioButton,
      TextInput,
      Textarea,
      Select,
      Card,
      RightSideBar,
      Icon
    },
    data(){
      return {
        isOpen: false,
        isLoading: true,
        isCountRemaining: false,
        isOpenAttachment: false,
        payGrade: {},
        levelOptions: [],
        getAllLevelOptions: [],
        locationOptions: [],
        designationOptions: [],
        employeesOptions: [],
        employeeInfo: {},
        isEffectiveDate: 'immediately',
        payload: {
          newOfficeId: '',
          justification: '',
          newDesignationId: '',
          newAnnualSalary: '',
          newManagerId: '',
          newLevelId: '',
          newfunctionId: '',
          effectiveDate: new Date(),
        },
      }
    },
    methods: {
      toggle({ userId, paygradeId }){
        this.getEmployeeCompensationSummary({ userId, paygradeId })
        this.isOpen = true
      },
      getEmployeeCompensationSummary(payload){
        this.isLoading = true
        this.$_getEmployeeCompensationSummary(payload).then(({ data }) => {
          this.employeeInfo = {
            ...data.employee,
            ...data.employee.paygrade,
            ...data.employee.userDetails,
          }
          this.isLoading = false
        }).catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 })
          this.isLoading = false
        })
      },
      async onChangeDesignation(){
        const designation = await _.find(this.designationOptions,
          $el => $el.id === this.payload.newDesignationId
        )
        this.levelOptions = await _.filter(this.getAllLevelOptions,
          $el => $el.id === designation?.levelId
        )
        const newOfficeId = await _.find(this.employeesOptions,
          $el => $el.userDesignation?.id === designation?.reportingTo
        )

        this.payload.newLevelId = designation?.levelId
        this.payload.newOfficeId = newOfficeId?.userId
        this.payload.newfunctionId = designation?.functionId
        await this.getPaygradeByOrgParameters()
      },

      onSubmit(){
        this.isLoading = true

        const payload = {
          ...this.payload,
          orgId: this.$orgId,
          initiatedBy: this.$AuthUser.id,
          userId: this.employeeInfo.userId,
          effectiveDate: format(this.payload.effectiveDate, "yyyy-MM-dd"),
        }

        this.$_compensationPromoteEmployee(payload).then(({ data }) => {
          this.$toasted.success(data.message, {duration: 3000})
          this.$emit('success', data.success)
          this.isLoading = false
          this.isOpen = false
        }).catch((error) => {
          this.$toasted.error(error.message, {duration: 3000})
          this.isLoading = false
        })
      },
      async getLocation() {
        this.$_getHumanarLocations().then(({ data }) => {
          this.locationOptions = data.outlets.map(item => ({
            value: item.id, ...item
          }))
        })
      },
      async getDesignation() {
        this.$_getDesignations('').then(({ data }) => {
          this.designationOptions = data.designations.map(item => ({
            value: item.id, ...item
          }))
        })
      },
      async getLevels() {
        this.$_getLevels().then(({ data }) => {
          this.getAllLevelOptions = data.levels.map(item => ({
            value: item.id, ...item
          }))
        })
      },
      async getHumanarEmployees(){
        this.$_getAllEmployeesWithQueries('').then(({ data }) => {
          this.employeesOptions = _.filter(data.data.employees,
            employee => !_.isEqual(employee.userId, this.employeeInfo.userId)
          )
        })
      },
      async getPaygradeByOrgParameters(){
        if(this.payload.newDesignationId && this.payload.newOfficeId
          && this.payload.newfunctionId && this.payload.newLevelId){

          const payload = {
            orgId: this.$orgId,
            levelId: this.payload.newLevelId,
            locationId: this.payload.newOfficeId,
            functionId: this.payload.newfunctionId,
            designationId: this.payload.newDesignationId,
          }

          await this.$_getPaygradeByOrgParameters(payload).then(({ data }) => {
            this.payGrade = data.data
          }).catch((error) => {
            this.payGrade = {}
            this.$toasted.error(error.message, {duration: 3000})
          })
        }
      }
    },
    computed: {
      checkAdjustment(){
        if(this.payload.newAnnualSalary){
          return !_.inRange(this.payload.newAnnualSalary,
            this.payGrade.rangeMinimum, this.payGrade.rangeMaximum
          )
        }
        return false
      },
    },
    async mounted(){
      await this.getLocation()
      await this.getLevels()
      await this.getDesignation()
      await this.getHumanarEmployees()
      this.isLoading = false
    },
  }
</script>

<style lang="scss">
  .rightSidebar{
    width: 540px !important;
  }
  .payInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  &_fakeInput {
    background: #f7f7ff;
    border-color: #878e99;
    padding: 10px 15px;
    height: 40px;
  }
}
</style>

